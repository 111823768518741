<div *ngIf="SHOW_BREADCRUMB" class="upper-banner" fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="6px">
    <a [routerLink]="['/c', 'application']">{{'Energy communities'|i18n}}</a>
    <div fxLayout="row" fxLayoutGap="6px">
        <span class="major-sign">></span><a [routerLink]="['/c', 'application-form']">{{'How to participate'|i18n}}</a>
    </div>
</div>
<div *ngIf="configurabilitySteps" class="application-form-container" fxLayout="column">
    <!-- fixed header -->
    <div #topContent fxLayout="row" fxLayoutAlign="center center">
        <div fxLayout="column" fxFlex="40" fxFlex.lt-md="90">
            <div class="title-0 mb-52">
                {{'Submit your application to join an energy community'|i18n}}
            </div>
        </div>
    </div>

    <div class="text-0">{{'Fill out the application form while keeping the following information handy'|i18n}}:</div>
    <ul class="mt-2 mb-52 text-0">
        <li>{{'your tax identification number'|i18n}}</li>
        <li>{{'one of your latest electricity bills'|i18n}}</li>
    </ul>
    <!-- stepper:

    ,******,,,,//(((/....,..,,,,***(((((((((((//(###(,*,,,,,,*///.                    ,*((((((((((((((((
    .,,,,**,,,,/*,.          .,,***/((((((((#(/(//////*,,,,,,/*                         /(((((((((((####
    ,..,,**,,.                   ,*/(((((*,,((//*******,,,,,.                 ...        ,((((((((((#(//
    ,..,,*,.            .         ./(((((*..#/****,,,,*,,,,.             .,,*,,**,,,..    .((((/*,,*#(//
    ...,,*,                   .     ,,,,,,,,#*,,,******,,,,           .,,********,,,,,..    ((((*..*#/**
    ...,,,.                          *******#*,,.**,,,*,,,(.    ....,,,,,,********,,,,,,    ./((/*,/#/**
    ...,,*.                  .      ,,,*****#,....,,,,*,,,//,    ....,,,********,.   ...,.  *,,,,,,(#*,,
    ....,,,            ......      ,,,*....,(,,,,,,,,,*///**,    ........,,,,,.. . .,..,*,,.,******(#*,,
    ,...,,,*.        ...,,,,,,,,,. .,,. # .,****,,,,,,/***,...   . ..     . ,**,,,...,****,,.*****,(*...
    ..........     ...,,,,,,,,,.,,,...,.%(.......,,***,,,,,,,*.   ....,,.....************,,..*,,,.,(/,,,
    .........,.. ......,,,.. ..,,,,....(#,............,,.,//*./*, .,,,,,,,*,.*//****,,,*,,,.. ...,,,,,,*
    ,,..,..            ..   .,,,,,....(#(.............,,**,,,*,,*. ..,,,**,,,,,,. .,,,.,,,,..//...,*****
    ,,,,,           ....,**,*...... .*(#.....................,,,,,....,,,,,...,**,..  .......*#*......,,
    ,,,,                .,,. ..    */(#*...................,...,,.,.. .....  ...,,**,.,... ..(#,........
      ..                   .     .*,/((..................,..             ......   .,,,..   .(##.........
    ,,,.     .             ...,.*///(/,...............                        ........   ./(##,.........
    ,,,                   .,,*/,/(*#(/................                     .,.         ,*/(##(..........
    ...                   *////,/,/((,........ .......                      ,,,,.    ,*/(##%#...........
    ,,,,          .       *///,//(/,/........ ........                .    .*//*,..,*/(##%##*......... .
    ....         %,,(/( /%%/*&,,##,/*..##.............          (/.(## * ((,%,.#%#%#(//##,%%//((#.......
    ....        ..  .. ...,//***//(/.......... .....                       ,,/(//,,/(**/((/,......  ....
    ..          .... ... .,///(**/*....... .                 .. ./(#% % %/.&(/%(/**#%%##(/#*./##./, ....
    .            ... ..... ./(/***/.....                    .       ..     *(((//,/*(//#(/,........... .
    .............it's beautiful......................|.....i've looked at this for five hours now.......
 -->
    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="center center" fxLayoutAlign.lt-md="start start"
         fxLayoutGap="10px" class="main-container stepper pt-20">
        <ng-container *ngFor="let step of STEPS; let i=index">
            <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
                <div [ngClass]="currentStep >= i ? 'circle' : 'circle disabled'" fxLayoutAlign="center center">
                    <div class="number" fxLayoutAlign="center center">{{i + 1}}</div>
                </div>
                <div class="text-4" [ngClass]="currentStep >= i ? 'subtitle' : 'subtitle disabled'">
                    {{(step)|i18n}}
                </div>
            </div>
            <div *ngIf="i+1 !== STEPS.length" class="line" fxFlex fxShow.lt-md="false"></div>
            <div *ngIf="i+1 !== STEPS.length" class="bar" fxFlex fxShow.gt-sm="false"></div>
        </ng-container>
    </div>
    <!-- step 1 -->
    <ng-container *ngIf="STEPS[currentStep] === PERSONAL_DATA">
        <form [formGroup]="personalDataForm">
            <div class="title-1">{{'Personal data'|i18n}}</div>
            <div fxLayout="column wrap" fxLayoutAlign='start center' fxLayout.lt-md="column" fxLayoutGap="30px" [ngClass]="'panel-limited'"
                 [ngClass.lt-md]="'panel'"
                 [ngStyle.gt-sm]="{'max-height': limitColumnHeight + 'px'}"
                 fxLayoutGap.lt-md="30px">
                <div class="min-width-50" fxLayout="row" fxLayoutAlign="center center"
                     *ngIf="configurabilitySteps?.stepPersonalData.subject_type">
                    <div fxLayout="column" fxLayoutGap="10px" fxFlex="60" fxFlex.lt-md="80">
                        <div class="text-1">{{('Juridical type'|i18n) + ':*'}}</div>
                        <mat-select app-small-select formControlName="juridicalType">
                            <mat-option *ngFor="let subject of ECJuridicalType | keyvalue" [value]="subject.value">
                                {{subject.value|ecMemberJuridicalType|i18n}}
                            </mat-option>
                        </mat-select>
                        <div *ngIf="isControlOK('juridicalType', personalDataForm)" app-error>
                            {{'Invalid juridical type'|i18n}}
                        </div>
                    </div>
                </div>
                <div class="min-width-50" fxLayout="row" fxLayoutAlign="center center"
                     *ngIf="configurabilitySteps?.stepPersonalData.subject_type &&
                     personalDataForm.controls.juridicalType.value === ECJuridicalType.INDIVIDUAL">
                    <div fxLayout="column" fxLayoutGap="10px" fxFlex="60" fxFlex.lt-md="80">
                        <div class="text-1">{{('Legal form type'|i18n) + ':*'}}</div>
                        <mat-select app-small-select formControlName="legalForm"
                                    [placeholder]="'Legal form'|i18n">
                            <mat-option *ngFor="let legalFormType of legalFormTypes" [value]="legalFormType">
                                {{ legalFormType|ecMemberLegalFormType|i18n }}
                            </mat-option>
                        </mat-select>
                        <div *ngIf="isControlOK('legalForm', personalDataForm)" app-error>
                            {{'Invalid legal form'|i18n}}
                        </div>
                    </div>
                </div>
                <div class="min-width-50" fxLayout="row" fxLayoutAlign="center center"
                     *ngIf="configurabilitySteps?.stepPersonalData.subject_type &&
                     personalDataForm.controls.juridicalType.value === ECJuridicalType.CORPORATE_ENTITY">
                    <div fxLayout="column" fxLayoutGap="10px" fxFlex="60" fxFlex.lt-md="80">
                        <div class="text-1">{{('Subject type'|i18n) + ':*'}}</div>
                        <mat-select app-small-select formControlName="subjectType"
                                    [placeholder]="'Subject type'|i18n">
                            <mat-option *ngFor="let subjectType of subjectTypes" [value]="subjectType">
                                {{ subjectType|ecMemberSubjectType|i18n }}
                            </mat-option>
                        </mat-select>
                        <div *ngIf="isControlOK('subjectType', personalDataForm)" app-error>
                            {{'Invalid subject type'|i18n}}
                        </div>
                    </div>
                </div>
                <div class="min-width-50" fxLayout="row" fxLayoutAlign="center center"
                     *ngIf="configurabilitySteps?.stepPersonalData.subject_type &&
                     personalDataForm.controls.subjectType.value === ECSubjectType.TERRITORIAL_BODY_LOCAL_AUTHORITY_OTHER_BODY">
                    <div fxLayout="column" fxLayoutGap="10px" fxFlex="60" fxFlex.lt-md="80">
                        <div class="text-1">{{('Entity subtype'|i18n) + ':*'}}</div>
                        <mat-select app-small-select formControlName="entitySubtype"
                                    [placeholder]="'Entity subtype'|i18n">
                            <mat-option *ngFor="let entitySubtype of entitySubtypes" [value]="entitySubtype">
                                {{ entitySubtype|ecMemberEntitySubtype|i18n }}
                            </mat-option>
                        </mat-select>
                        <div *ngIf="isControlOK('entitySubtype', personalDataForm)" app-error>
                            {{'Invalid subject type'|i18n}}
                        </div>
                    </div>
                </div>
                <div class="min-width-50" *ngIf="isCompany && configurabilitySteps?.stepPersonalData.company_name"
                     fxLayout="row" fxLayoutAlign="center center">
                    <div fxLayout="column" fxLayoutGap="10px" fxFlex="60" fxFlex.lt-md="80">
                        <div class="text-1">{{('Company name'|i18n) + ':*'}}</div>
                        <div app-input-container>
                            <input app-input formControlName="companyName" [maxLength]="DEFAULT_MAX_CHARS">
                            <div *ngIf="isControlOK('companyName', personalDataForm)" app-error>
                                {{'Invalid company name'|i18n}}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="min-width-50" fxLayout="row" fxLayoutAlign="center center"
                     *ngIf="configurabilitySteps?.stepPersonalData.first_name">
                    <div fxLayout="column" fxLayoutGap="10px" fxFlex="60" fxFlex.lt-md="80">
                        <div class="text-1">{{('First name'|i18n) + ':*'}}</div>
                        <div app-input-container>
                            <input app-input formControlName="firstName" [maxLength]="NAME_MAX_CHARS">
                            <div *ngIf="isControlOK('firstName', personalDataForm)" app-error>
                                {{'Invalid first name'|i18n}}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="min-width-50" fxLayout="row" fxLayoutAlign="center center"
                     *ngIf="configurabilitySteps?.stepPersonalData.last_name">
                    <div fxLayout="column" fxLayoutGap="10px" fxFlex="60" fxFlex.lt-md="80">
                        <div class="text-1">{{('Last name'|i18n) + ':*'}}</div>
                        <div app-input-container>
                            <input app-input formControlName="lastName" [maxLength]="NAME_MAX_CHARS">
                            <div *ngIf="isControlOK('lastName', personalDataForm)" app-error>
                                {{'Invalid last name'|i18n}}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="min-width-50" fxLayout="column" fxLayoutGap="16px"
                     *ngIf="configurabilitySteps?.stepPersonalData.address">
                    <div fxLayout="row" fxLayoutAlign="center center">
                        <div fxLayout="column" fxLayoutGap="12px" fxFlex="60" fxFlex.lt-md="80">
                            <div class="text-4">{{isCompany ?
                                ('Headquarters address'|i18n) :
                                ('Residential address'|i18n)}}:*
                            </div>
                            <div>
                                <app-google-maps-address-autocomplete [formControlRef]="addressFormControl"
                                                                      [isRequired]="configurabilitySteps.stepPersonalData.address"
                                                                      (addressChange)="addressChange($event)">
                                </app-google-maps-address-autocomplete>
                                <div *ngIf="isControlOK('address', personalDataForm)" app-error>
                                    {{'Invalid address. Search the nearest address'|i18n}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="min-width-50" *ngIf="isCompany &&
                                configurabilitySteps?.stepPersonalData.piva"
                     fxLayout="row" fxLayoutAlign="center center">
                    <div fxLayout="column" fxLayoutGap="10px" fxFlex="60" fxFlex.lt-md="80">
                        <div class="text-1">{{('VAT number'|i18n) + ':*'}}</div>
                        <div app-input-container>
                            <input app-input formControlName="vatNumber" [maxLength]="VAT_NUMBER_MAX_CHARS">
                            <div *ngIf="isControlOK('vatNumber', personalDataForm)" app-error>
                                {{'Invalid VAT number'|i18n}}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="min-width-50" *ngIf="!isCompany &&
                     configurabilitySteps?.stepPersonalData.tax_id"
                     fxLayout="row" fxLayoutAlign="center center">
                    <div fxLayout="column" fxLayoutGap="10px" fxFlex="60" fxFlex.lt-md="80">
                        <div class="text-1">{{('Tax code'|i18n) + ':*'}}</div>
                        <div app-input-container>
                            <input app-input formControlName="taxCode" [maxLength]="TAX_ID_MAX_CHARS">
                            <div *ngIf="isControlOK('taxCode', personalDataForm)" app-error>
                                {{'Invalid tax code'|i18n}}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="min-width-50" fxLayout="row" fxLayoutAlign="center center"
                     *ngIf="configurabilitySteps?.stepPersonalData.email">
                    <div fxLayout="column" fxLayoutGap="10px" fxFlex="60" fxFlex.lt-md="80">
                        <div class="text-1">{{('E-mail'|i18n) + ':*'}}</div>
                        <div app-input-container>
                            <input app-input formControlName="email" [maxLength]="EMAIL_MAX_CHARS">
                            <div *ngIf="isControlOK('email', personalDataForm)" app-error>
                                {{'Invalid e-mail'|i18n}}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="min-width-50" fxLayout="row" fxLayoutAlign="center center"
                     *ngIf="configurabilitySteps?.stepPersonalData.email">
                    <div fxLayout="column" fxLayoutGap="10px" fxFlex="60" fxFlex.lt-md="80">
                        <div class="text-1">{{('Confirm e-mail'|i18n) + ':*'}}</div>
                        <div app-input-container>
                            <input app-input formControlName="confirmEmail" [maxLength]="EMAIL_MAX_CHARS"
                                   (paste)="false" (copy)="false" (cut)="false">
                            <div *ngIf="isControlOK('confirmEmail', personalDataForm) &&
                                !personalDataForm.hasError('different_emails')" app-error>
                                {{'Invalid e-mail'|i18n}}
                            </div>
                            <div *ngIf="personalDataForm.hasError('different_emails')" app-error>
                                {{'E-mails are different'|i18n}}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="min-width-50" fxLayout="row" fxLayoutAlign="center center"
                     *ngIf="configurabilitySteps?.stepPersonalData.pec">
                    <div fxLayout="column" fxLayoutGap="10px" fxFlex="60" fxFlex.lt-md="80">
                        <div class="text-1">{{('PEC'|i18n) + ':'}}</div>
                        <div app-input-container>
                            <input app-input formControlName="pec" [maxLength]="EMAIL_MAX_CHARS">
                            <div *ngIf="isControlOK('pec', personalDataForm)" app-error>
                                {{'Invalid PEC'|i18n}}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="min-width-50" fxLayout="row" fxLayoutAlign="center center"
                     *ngIf="configurabilitySteps?.stepPersonalData.mobile_number">
                    <div fxLayout="column" fxLayoutGap="10px" fxFlex="60" fxFlex.lt-md="80">
                        <div class="text-1">{{('Mobile'|i18n) + ':*'}}</div>
                        <div app-input-container>
                            <input app-input formControlName="mobile" [maxLength]="MOBILE_MAX_CHARS">
                            <div *ngIf="isControlOK('mobile', personalDataForm)" app-error>
                                {{'Invalid mobile'|i18n}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ng-container *ngIf="isDefined(docTypes)">
                <ng-container *ngFor="let doc of docTypes">
                    <div fxLayout="column" fxLayoutGap="30px" class="panel">
                        <div class="title-2 ml-36">
                            {{getDocName(doc.name)|i18n}}
                            <span *ngIf="doc.optional">({{'optional'|i18n}})</span>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="center center">
                            <div fxLayout="column" fxLayoutGap="10px" fxFlex="40" fxFlex.lt-md="80">
                                <div class="text-1">{{('Upload document'|i18n) + ':*'}}</div>
                                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between center"
                                     fxLayoutGap="10px">
                                    <input #fileSelect type="file" style="display: none"
                                           [accept]="ACCEPTED_MIME_TYPES"
                                           (change)="onUploadDocumentFile(doc.name, $event)">
                                    <button app-small-primary-button (click)="fileSelect.click()" type="button">
                                        <app-icon *ngIf="uploadedFileName(doc.name)" name="fal-sync"></app-icon>
                                        <app-icon *ngIf="!uploadedFileName(doc.name)" name="fal-upload"></app-icon>
                                        {{(uploadedFileName(doc.name) ? 'Change file' : 'Upload file')|i18n}}
                                    </button>
                                    <div *ngIf="uploadedFileName(doc.name)">{{uploadedFileName(doc.name)}}</div>
                                    <button *ngIf="uploadedFileName(doc.name)" app-icon-button class="app-button-alert"
                                            [title]="'Remove file'|i18n"
                                            (click)="onClickRemoveDocument(doc.name)" type="button">
                                        <app-icon name="fal-trash-alt"></app-icon>
                                    </button>
                                </div>
                                <div class="text-2" fxLayout="row" fxLayoutAlign.lt-md="center">
                                    {{'(Max {0} MB. Accepts only {1}.)'|i18n:MAX_FILE_MB:mimeTypesNames}}
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </ng-container>
            <ng-container *ngIf="isDefined(instanceCommunities)">
                <ng-container>
                    <div fxLayout="column" fxLayoutGap="30px" class="panel">
                        <div fxLayout="row" fxLayoutAlign="center center">
                            <div fxLayout="column" fxLayoutGap="10px" fxFlex="40" fxFlex.lt-md="80">
                                <div class="text-1">{{('Eventual community preference'|i18n) + ':'}}</div>
                                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between center"
                                     fxLayoutGap="10px">
                                    <mat-select app-small-select formControlName="preferredCommunity"
                                                [placeholder]="(instanceCommunities.length > 0 ? '' : 'No selectable communities')|i18n">
                                        <mat-option *ngFor="let comm of instanceCommunities" [value]="comm">
                                            {{comm.name|i18n}}
                                        </mat-option>
                                    </mat-select>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </ng-container>
        </form>
    </ng-container>
    <!-- step 2 -->
    <ng-container *ngIf="STEPS[currentStep] === YOUR_UTILITIES">
        <form [formGroup]="utilitiesForm">
            <div class="title-1">{{'Your utilities'|i18n}}</div>
            <div fxLayout="column" fxLayoutGap="30px" class="panel">
                <div *ngFor="let pod of pods, let i = index" fxLayout="column" fxLayoutGap="30px">
                    <div fxLayout="row" fxLayoutAlign="center center">
                        <div fxLayout="column" fxLayoutGap="10px" fxFlex="40" fxFlex.lt-md="80">
                            <div *ngIf="pods.length > 1" class="title-2">{{'POD ' + (i + 1)}}</div>
                        </div>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="center center"
                         *ngIf="configurabilitySteps?.stepUtilities.pod">
                        <div fxLayout="column" fxLayoutGap="10px" fxFlex="40" fxFlex.lt-md="80">
                            <div class="text-1">{{('POD code'|i18n) + ':*'}}</div>
                            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
                                <div app-input-container fxFlex>
                                    <input app-input [formControlName]="'pod_'+pod+'_code'" [maxLength]="POD_MAX_CHARS">
                                    <div *ngIf="getPodError('pod_'+pod+'_code') === 'unique_pod'" app-error>
                                        {{'POD already existing'|i18n}}
                                    </div>
                                    <div *ngIf="getPodError('pod_'+pod+'_code') === 'duplicate_pods'" app-error>
                                        {{'Duplicate POD number'|i18n}}
                                    </div>
                                    <div *ngIf="getPodError('pod_'+pod+'_code') === 'invalid'" app-error>
                                        {{'Invalid POD code'|i18n}}
                                    </div>
                                </div>
                                <app-icon name="fas-info-circle" class="primary-heading"
                                          title="{{'Alphanumeric code with 14 to 15 characters, as seen in electric bills. Example: IT001E12345678'|i18n}}"></app-icon>
                                <button *ngIf="pods.length > 1" app-icon-button class="app-button-alert"
                                        [title]="'Remove POD'|i18n"
                                        (click)="onClickRemovePOD(pod)">
                                    <app-icon name="fal-trash-alt"></app-icon>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="center center"
                         *ngIf="configurabilitySteps?.stepUtilities.pod_utility_type">
                        <div fxLayout="column" fxLayoutGap="10px" fxFlex="40" fxFlex.lt-md="80">
                            <div class="text-1">{{('POD utility type'|i18n) + ':*'}}</div>
                            <mat-select app-small-select [formControlName]="'pod_'+pod+'_type'">
                                <mat-option *ngFor="let utilityType of getPodUtilityTypes(
                                personalDataForm.controls.juridicalType.value,
                                personalDataForm.controls.legalForm.value,
                                personalDataForm.controls.subjectType.value,
                                personalDataForm.controls.entitySubtype.value)"
                                            [value]="utilityType">
                                    {{utilityType|genericPipe}}
                                </mat-option>
                            </mat-select>
                        </div>
                    </div>
                    <div fxLayout="column" fxLayoutGap="16px" fxFlex="60"
                         *ngIf="configurabilitySteps?.stepUtilities.pod_address">
                        <div fxLayout="row" fxLayoutAlign="center center">
                            <div fxLayout="column" fxLayoutGap="12px" fxFlex="40" fxFlex.lt-md="80">
                                <div class="text-1">{{'Utility address'|i18n}}:*</div>
                                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px"
                                     fxFlex="50" *ngIf="configurabilitySteps.stepPersonalData.address">
                                    <mat-slide-toggle [formControlName]="'pod_'+pod+'_address_like_member'"
                                                      class="toggle txt-p-16-4 mr-22">
                                    </mat-slide-toggle>
                                    <div class="text-5">{{'Same address of residence / office'|i18n }}</div>
                                </div>
                                <div>
                                    <div fxLayout="row" fxLayoutGap="12px" fxLayoutAlign="start stretch"
                                         *ngIf="addressPodFormControl(pod).enabled">
                                        <app-google-maps-address-autocomplete
                                                class="w-100"
                                                [formControlRef]="addressPodFormControl(pod)"
                                                [isRequired]="configurabilitySteps.stepUtilities.pod_address"
                                                (addressChange)="addressChange($event, pod)">
                                        </app-google-maps-address-autocomplete>
                                    </div>
                                    <div fxLayout="row" fxLayoutGap="12px" fxLayoutAlign="start stretch"
                                         *ngIf="addressPodFormControl(pod).disabled">
                                        <div fxFlex app-input-container class="address-field">
                                            <input app-input
                                                   class="input-font"
                                                   [formControl]="addressPodFormControl(pod)">
                                        </div>
                                    </div>
                                    <div *ngIf="isControlOK('pod_'+pod+'_address', utilitiesForm)" app-error>
                                        {{'Invalid address. Search the nearest address'|i18n}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="center center"
                         *ngIf="configurabilitySteps?.stepUtilities.wifi">
                        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" fxFlex="40" fxFlex.lt-md="80">
                            <div class="text-1">{{('Wi-Fi'|i18n) + '*'}}:</div>
                            <mat-slide-toggle class="toggle" [formControlName]="'pod_'+pod+'_wifi'"></mat-slide-toggle>
                        </div>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="center center"
                         *ngIf="configurabilitySteps?.stepUtilities.has_utility_plant">
                        <div fxLayout="column" fxLayoutGap="18px" fxFlex="40" fxFlex.lt-md="80">
                            <div class="text-1">{{('How would you like to participate with this utility?'|i18n) + '*'}}</div>
                            <mat-radio-group [formControlName]="'pod_'+pod+'_utility_type'" fxLayout="column"
                                             fxLayoutGap="16px">
                                <mat-radio-button app-radio-button
                                                  *ngFor="let type of (HasUtilityPlant | keyvalue) | sortArray:'key':true"
                                                  [value]="type.value">
                                    <div class="radio-option">
                                        {{type.value|hasUtilityPlant}}
                                        <app-icon *ngIf="type.value === HasUtilityPlant.YES" name="fas-info-circle"
                                                  class="primary-heading"
                                                  title="{{'You are a prosumer if you own a production plant, such as a photovoltaic panel.'|i18n}}">
                                        </app-icon>
                                        <app-icon *ngIf="type.value === HasUtilityPlant.FUTURE" name="fas-info-circle"
                                                  class="primary-heading"
                                                  title="{{'You are an aspiring prosumer if you do not own any production plant, such as a photovoltaic panel, but would like to install one.'|i18n}}">
                                        </app-icon>
                                    </div>
                                </mat-radio-button>
                            </mat-radio-group>
                        </div>
                    </div>
                    <ng-container *ngIf="utilitiesForm.controls['pod_'+pod+'_utility_type'].value == HasUtilityPlant.YES">
                        <div fxLayout="row" fxLayoutAlign="center center"
                             *ngIf="configurabilitySteps?.stepUtilities.utility_production_type">
                            <div fxLayout="column" fxLayoutGap="10px" fxFlex="40" fxFlex.lt-md="80">
                                <div class="text-1">{{('Production plant type'|i18n) + ':*'}}</div>
                                <mat-select app-small-select [formControlName]="'pod_'+pod+'_plant_type'">
                                    <mat-option *ngFor="let upt of UtilityProductionType | keyvalue" [value]="upt.value">
                                        {{upt.value|genericPipe}}
                                    </mat-option>
                                </mat-select>
                            </div>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="center center"
                             *ngIf="configurabilitySteps?.stepUtilities.power">
                            <div fxLayout="column" fxLayoutGap="10px" fxFlex="40" fxFlex.lt-md="80">
                                <div class="text-1">{{('Production plant power'|i18n) + ':*'}}</div>
                                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
                                    <div app-input-container fxFlex>
                                        <input app-input [formControlName]="'pod_'+pod+'_power'"
                                               [maxLength]="DEFAULT_MAX_CHARS">
                                        <div *ngIf="isControlOK('pod_'+pod+'_power', utilitiesForm)" app-error>
                                            {{'Invalid value'|i18n}}
                                        </div>
                                    </div>
                                    <span>kW</span>
                                </div>
                            </div>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="center center"
                             *ngIf="configurabilitySteps?.stepUtilities.pv_plant_effective_date">
                            <div fxLayout="column" fxLayoutGap="10px" fxFlex="40" fxFlex.lt-md="80">
                                <div class="text-1">{{('Plant effective date'|i18n) + ':'}}</div>
                                <div fxLayout="row" fxLayoutAlign="space-between center" app-input-container fxLayoutGap="32px">
                                    <ng-container *ngIf="plantHasDate(pod)">
                                        <div app-input-container fxLayout="row" fxFlex
                                             fxLayoutAlign="start center" fxLayoutGap="4px">
                                            <input app-input [matDatepicker]="effectiveDatePicker"
                                                   [formControlName]="'pod_'+pod+'_pv_plant_effective_date'">
                                            <mat-datepicker #effectiveDatePicker></mat-datepicker>
                                        </div>
                                        <button app-ghost-button (click)="onClickResetPlantDate(pod)">
                                            <app-icon name="fal-calendar-alt"></app-icon>
                                            {{'Reset date'|i18n }}
                                        </button>
                                    </ng-container>
                                    <button *ngIf="!plantHasDate(pod)" app-ghost-button
                                            fxLayoutAlign="space-around center"
                                            (click)="onClickSetPlantDate(pod)">
                                        <app-icon name="fal-calendar-alt"></app-icon>
                                        {{'Insert date'|i18n }}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <div fxLayout="row" fxLayoutAlign="center center"
                         *ngIf="configurabilitySteps?.stepUtilities.provider">
                        <div fxLayout="column" fxLayoutGap="10px" fxFlex="40" fxFlex.lt-md="80">
                            <div class="text-1">{{('What is your energy provider for this utility'|i18n) + '?'}}</div>
                            <div app-input-container>
                                <input app-input [formControlName]="'pod_'+pod+'_provider'" [maxLength]="DEFAULT_MAX_CHARS">
                                <div *ngIf="isControlOK('pod_'+pod+'_provider', utilitiesForm)" app-error>
                                    {{'Invalid energy provider'|i18n}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="center center">
                        <div app-divider class="divider" fxFlex="80"></div>
                    </div>
                </div>
                <div fxLayout="row" fxLayoutAlign="center center">
                    <button app-ghost-button type="button" (click)="addAnotherPod()">
                        <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px">
                            <app-icon name="fal-plus-square"></app-icon>
                            <span>{{'Add another POD'|i18n}}</span>
                        </div>
                    </button>
                </div>
            </div>
        </form>
    </ng-container>
    <!-- step 3 -->
    <ng-container *ngIf="STEPS[currentStep] === ENERGY_PROFILING">
        <form [formGroup]="profilationsForm">
            <div class="title-1">{{'Energy profiling'|i18n}}</div>
            <div *ngFor="let pod of pods" fxLayout="column" fxLayoutGap="30px" class="panel">
                <div fxLayout="column" fxLayoutGap="30px">
                    <div fxLayout="row" fxLayoutAlign="start center">
                        <div fxFlex="10"></div>
                        <div fxLayout="column" fxLayoutGap="16px">
                            <div class="title-2">{{('POD profile'|i18n) + ' ' + getPodCode(pod)}}</div>
                            <div class="title-3">{{'Define the consumption profile for this utility'|i18n}}:</div>
                        </div>
                        <div fxFlex="10"></div>
                    </div>
                    <mat-radio-group [formControlName]="'pod_'+pod+'_second_part'" fxLayout="column"
                                     fxLayoutGap="16px">
                        <div *ngIf="dontHideFirstOption(pod)" fxLayout="row">
                            <div fxFlex="30"></div>
                            <mat-radio-button app-radio-button fxLayoutAlign="start center" [value]=false
                                              [checked]="!secondPartPodValue(pod)">
                                <div *ngIf="utilitiesForm.controls['pod_'+pod+'_type'].value === RESIDENTIAL">
                                    {{'Household appliances data'|i18n}}:
                                </div>
                                <div *ngIf="utilitiesForm.controls['pod_'+pod+'_type'].value === CONDOMINIUM">
                                    {{'Number of households'|i18n}}:
                                </div>
                                <div *ngIf="utilitiesForm.controls['pod_'+pod+'_type'].value === SME_FACTORY ">
                                    {{'Number of employees'|i18n}}:
                                </div>
                                <div *ngIf="(utilitiesForm.controls['pod_'+pod+'_type'].value !== RESIDENTIAL) &&
                                        (utilitiesForm.controls['pod_'+pod+'_type'].value !== CONDOMINIUM) &&
                                        (utilitiesForm.controls['pod_'+pod+'_type'].value !== SME_FACTORY)">
                                    {{'Surface area of the utility site'|i18n}}:
                                </div>
                            </mat-radio-button>
                            <div fxFlex="30"></div>
                        </div>
                        <ng-container *ngIf="!isCompany && ecmLinked">
                            <div fxLayout="row" fxLayoutAlign="center center">
                                <div fxLayout="column" fxLayoutGap="10px" fxFlex="25" fxFlex.lt-md="80">
                                    <div [ngClass]="'text-' + secondPartPodValue(pod)">{{('Number of people using the utility'|i18n) + ':'}}</div>
                                    <div app-input-container>
                                        <input [ngClass]="'text-' + secondPartPodValue(pod)"
                                               app-input [formControlName]="'pod_'+pod+'_people'"
                                               [maxLength]="PROFILATIONS_MAX_CHARS"
                                               [attr.disabled]="secondPartPodValue(pod) ? '' : null">
                                        <div *ngIf="isControlOK('pod_'+pod+'_people', profilationsForm)" app-error>
                                            {{'Invalid value'|i18n}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div fxLayout="row" fxLayoutAlign="center center">
                                <div fxLayout="column" fxLayoutGap="10px" fxFlex="25" fxFlex.lt-md="80">
                                    <div [ngClass]="'text-' + secondPartPodValue(pod)">{{('Number of televisions'|i18n) + ':'}}</div>
                                    <div app-input-container>
                                        <input [ngClass]="'text-' + secondPartPodValue(pod)"
                                               app-input [formControlName]="'pod_'+pod+'_tv'"
                                               [maxLength]="PROFILATIONS_MAX_CHARS"
                                               [attr.disabled]="secondPartPodValue(pod) ? '' : null">
                                        <div *ngIf="isControlOK('pod_'+pod+'_tv', profilationsForm)" app-error>
                                            {{'Invalid value'|i18n}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div fxLayout="row" fxLayoutAlign="center center">
                                <div fxLayout="column" fxLayoutGap="10px" fxFlex="25" fxFlex.lt-md="80">
                                    <div [ngClass]="'text-' + secondPartPodValue(pod)">{{('Number of computers'|i18n) + ':'}}</div>
                                    <div app-input-container>
                                        <input [ngClass]="'text-' + secondPartPodValue(pod)"
                                               app-input [formControlName]="'pod_'+pod+'_pc'"
                                               [maxLength]="PROFILATIONS_MAX_CHARS"
                                               [attr.disabled]="secondPartPodValue(pod) ? '' : null">
                                        <div *ngIf="isControlOK('pod_'+pod+'_pc', profilationsForm)" app-error>
                                            {{'Invalid value'|i18n}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div fxLayout="row" fxLayoutAlign="center center">
                                <div fxLayout="column" fxLayoutGap="10px" fxFlex="25" fxFlex.lt-md="80">
                                    <div [ngClass]="'text-' + secondPartPodValue(pod)">{{('Number of air conditioners'|i18n) + ':'}}</div>
                                    <div app-input-container>
                                        <input [ngClass]="'text-' + secondPartPodValue(pod)"
                                               app-input [formControlName]="'pod_'+pod+'_ac'"
                                               [maxLength]="PROFILATIONS_MAX_CHARS"
                                               [attr.disabled]="secondPartPodValue(pod) ? '' : null">
                                        <div *ngIf="isControlOK('pod_'+pod+'_ac', profilationsForm)" app-error>
                                            {{'Invalid value'|i18n}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div fxLayout="row" fxLayoutAlign="center center" class="mt-6">
                                <div fxLayout="column" fxLayoutGap="10px" fxFlex="25" fxFlex.lt-md="80">
                                    <mat-checkbox app-checkbox [formControlName]="'pod_'+pod+'_dishwasher'"
                                                  [disabled]="secondPartPodValue(pod)">
                                        <div [ngClass]="'text-' + secondPartPodValue(pod)">{{'Dishwasher use'|i18n}}</div>
                                    </mat-checkbox>
                                </div>
                            </div>
                            <div fxLayout="row" fxLayoutAlign="center center">
                                <div fxLayout="column" fxLayoutGap="10px" fxFlex="25" fxFlex.lt-md="80">
                                    <mat-checkbox app-checkbox [formControlName]="'pod_'+pod+'_heater'"
                                                  [disabled]="secondPartPodValue(pod)">
                                        <div [ngClass]="'text-' + secondPartPodValue(pod)">{{'Heater use'|i18n}}</div>
                                    </mat-checkbox>
                                </div>
                            </div>
                        </ng-container>
                        <div *ngIf="isCompany &&
                        utilitiesForm.controls['pod_'+pod+'_type'].value && dontHideFirstOption(pod)"
                             fxLayout="row" fxLayoutAlign="center center">
                            <div fxLayout="column" fxLayoutGap="10px" fxFlex="25" fxFlex.lt-md="80">
                                <div fxLayoutAlign="start center">
                                    <mat-select app-small-select [formControlName]="'pod_'+pod+'_custom_range'"
                                                [disabled]="secondPartPodValue(pod)">
                                        <mat-option [ngClass]="'text-' + secondPartPodValue(pod)"
                                                    *ngFor="let val of UtilitiesClassConsRanges[utilitiesForm.controls['pod_'+pod+'_type'].value]"
                                                    [value]="val">
                                            {{translateRangeFor(val)}}
                                        </mat-option>
                                    </mat-select>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="dontHideFirstOption(pod)" fxLayout="row" fxLayoutAlign="start center">
                            <div fxFlex="37.5" fxFlex.lt-md="10"></div>
                            <div fxLayout="column" fxLayoutGap="10px" fxFlex.lt-md="80">
                                <div fxLayout="row" fxLayoutAlign="start center">
                                    <div class="text-1">{{'Estimated consumption: {0} kWh/year'|i18n:estimatedYearlyConsForPod(pod)}}.</div>
                                </div>
                            </div>
                        </div>
                        <div fxLayout="row">
                            <div fxFlex="30"></div>
                            <mat-radio-button app-radio-button fxLayoutAlign="start center" [value]=true>
                                <span>{{('Yearly consumption'|i18n) + ':'}}</span>
                            </mat-radio-button>
                            <div fxFlex="30"></div>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="center center">
                            <div fxLayout="column" fxLayoutGap="10px" fxFlex="25" fxFlex.lt-md="80">
                                <div fxLayout="row" fxLayoutAlign="start center">
                                    <div app-input-container fxFlex>
                                        <input [ngClass]="'text-' + !secondPartPodValue(pod)"
                                               app-input [formControlName]="'pod_'+pod+'_yearly_consumption'"
                                               [maxLength]="TOTAL_CONSUMPTION_MAX_CHARS"
                                               [attr.disabled]="!secondPartPodValue(pod) ? '' : null">
                                        <div *ngIf="isControlOK('pod_'+pod+'_yearly_consumption', profilationsForm)" app-error>
                                            {{'Invalid value'|i18n}}
                                        </div>
                                    </div>
                                    <div [ngClass]="'text-' + !secondPartPodValue(pod) + ' ml-20'">{{'kWh/year'|i18n}}</div>
                                </div>
                            </div>
                        </div>
                    </mat-radio-group>
                </div>
            </div>
        </form>
    </ng-container>
    <!-- step 4 -->
    <ng-container *ngIf="STEPS[currentStep] === ACCEPT_POLICY">
        <form [formGroup]="acceptanceForm">
            <div class="title-1">{{'Accept Policy'|i18n}}</div>
            <div fxLayout="row" fxLayoutAlign="center center" class="panel">
                <div fxLayout="column" fxLayoutGap="10px" fxFlex="25" fxFlex.lt-md="80">
                    <mat-checkbox app-checkbox color="primary" formControlName="policy">
                        <a *ngIf="privacyPolicyLink" [href]=privacyPolicyLink target="_blank"
                           rel="noopener noreferrer">{{('Accept Privacy Policy'|i18n) + '*'}}</a>
                        <span *ngIf="!privacyPolicyLink">{{('Accept Privacy Policy'|i18n) + '*'}}</span>
                    </mat-checkbox>
                </div>
            </div>
        </form>
    </ng-container>
    <ng-container *ngIf="(STEPS[currentStep] === PERSONAL_DATA) && PROSPECTUS_TITLE && PROSPECTUS_BODY">
        <div class="prospect">
            <div class="prospect-title" [innerHTML]="PROSPECTUS_TITLE|i18n"></div>
            <div fxLayout="column" fxLayoutGap="8px">
                <div *ngFor="let PROSP of PROSPECTUS_BODY">
                    <div class="prospect-body mt-2" [innerHTML]="PROSP|i18n"></div>
                </div>
            </div>
        </div>
    </ng-container>
    <div fxLayout="row" fxLayoutAlign="space-evenly center" class="mt-42">
        <button *ngIf="currentStep > 0" app-ghost-button type="button"
                (click)="prevStep(topContent)">
            <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px">
                <app-icon name="fal-arrow-left"></app-icon>
                <span>{{'Back'|i18n}}</span>
            </div>
        </button>
        <button *ngIf="currentStep < STEPS.length - 1" app-primary-button type="button"
                [disabled]="!isNextEnabled(STEPS[currentStep])"
                (click)="nextStep(topContent)">
            <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px">
                <span>{{'Next'|i18n}}</span>
                <app-icon name="fal-arrow-right" class="right-icon"></app-icon>
            </div>
        </button>
        <button *ngIf="currentStep === STEPS.length - 1" app-primary-button type="button"
                [disabled]="!isPolicyValid"
                (click)="sendIt()">
            {{'Submit application'|i18n}}
        </button>
    </div>
    <div *ngIf="currentStep === STEPS.length - 1" fxLayout="row" fxLayoutAlign="center center" class="mt-32">
        <div fxLayout="column" fxLayoutAlign="center center" fxFlex="60" fxFlex.lt-md="80">
            <div class="text-3">{{'You will be contacted to finalize your entry in the energy community.'|i18n}}</div>
        </div>
    </div>
</div>

<ng-template #spinner let-data="data" let-close="close">
    <app-spinner [spinnerDataSubj]="data"
                 (closeSpinner)="close()">
    </app-spinner>
</ng-template>

<ng-template #setPlantDate let-data let-close="close">
    <div class="light-background">
        <div fxLayout="row" fxLayoutAlign="center center" class="ml-42 mr-42 mt-30">
            {{('Enter the date the system was connected to the grid'|i18n) + '.'}}
        </div>
        <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="32px" class="mt-30">
            <div>{{('Choose date'|i18n) + ':*'}}</div>
            <div app-input-container>
                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="4px">
                    <input app-input [matDatepicker]="podEffectiveDate"
                           [formControl]="data.date">
                    <mat-datepicker-toggle matSuffix [for]="podEffectiveDate"></mat-datepicker-toggle>
                    <mat-datepicker #podEffectiveDate></mat-datepicker>
                </div>
                <div *ngIf="data.date.invalid && data.date.dirty" app-error>
                    {{'Invalid date'|i18n}}
                </div>
            </div>
        </div>
        <div class="mt-30"></div>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-between" app-dialog-actions>
        <button app-ghost-button (click)="close(false)">
            {{'Cancel'|i18n}}
        </button>
        <button app-primary-button [disabled]="data.date.invalid && data.date.dirty" (click)="close(data.date.value)">
            {{'Confirm date'|i18n}}
        </button>
    </div>
</ng-template>
