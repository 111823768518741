import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    NgZone,
    OnDestroy,
    Output,
    ViewChild
} from '@angular/core';
import {ECMemberAddress, SpatialCoordinates} from 'smag-commons/cfg/hierarchy';
import {MapsAPILoader} from '@agm/core';
import {isDefined} from '../../commons/commons';
import {FormControl} from '@angular/forms';

export interface Address {
    street: string;
    streetNumber: string;
    postalCode: string;
    locality: string;
    municipality: string;
    province: string;
    region: string;
    country: string;
    coordinates: SpatialCoordinates;
    isValid: boolean;
    errors: string[];
}

export enum GoogleTypes {
    STREET_NUMBER = 'street_number',
    ROUTE = 'route',
    LOCALITY = 'locality',
    ADMINISTRATIVE_AREA_LEVEL_3 = 'administrative_area_level_3', // municipality
    ADMINISTRATIVE_AREA_LEVEL_2 = 'administrative_area_level_2', // province
    ADMINISTRATIVE_AREA_LEVEL_1 = 'administrative_area_level_1', // region
    COUNTRY = 'country', // country
    POSTAL_CODE = 'postal_code', // postal code
}

@Component({
    selector: 'app-google-maps-address-autocomplete',
    templateUrl: './google-maps-address-autocomplete.component.html',
    styleUrls: ['./google-maps-address-autocomplete.component.scss']
})
export class GoogleMapsAddressAutocompleteComponent implements AfterViewInit, OnDestroy {
    private autocomplete: google.maps.places.Autocomplete = null;
    private autocompleteListener: google.maps.MapsEventListener = null;
    address: Address = null;

    @ViewChild('search', {read: ElementRef}) private searchElementRef: ElementRef;
    @Input() formControlRef: FormControl;
    @Input() isRequired = true; // is address input required
    @Output() addressChange: EventEmitter<Address> = new EventEmitter<Address>();

    readonly isDefined = isDefined;

    constructor(private mapsAPILoader: MapsAPILoader,
                private ngZone: NgZone,
                private changeDetectorRef: ChangeDetectorRef) {
    }

    ngAfterViewInit() {
        this.initFormControl();
        this.addSearchListener();
        this.mapsAPILoader.load().then(() => this.setAddress());
    }

    ngOnDestroy() {
        this.autocomplete?.unbindAll();
        this.autocompleteListener?.remove();
    }

    loseFocus() {
        google.maps.event.trigger(this.searchElementRef.nativeElement, 'focus', {});
        google.maps.event.trigger(this.searchElementRef.nativeElement, 'keydown', {keyCode: 13});
    }

    private initFormControl() {
        if (this.formControlRef) {
            this.searchElementRef.nativeElement.value = this.formControlRef.value;
        }
    }

    private addSearchListener() {
        this.searchElementRef.nativeElement.addEventListener('change', () => {
            if (this.searchElementRef.nativeElement.value === '') {
                this.address = {
                    street: null,
                    streetNumber: null,
                    postalCode: null,
                    locality: null,
                    municipality: null,
                    province: null,
                    region: null,
                    country: null,
                    coordinates: null,
                    isValid: !this.isRequired,
                    errors: this.isRequired ? ['Missing address'] : []
                };
                this.setFormErrors();
                this.formControlRef.setValue(null);
            }
        });
    }

    private initAddress() {
        this.address = {
            street: null,
            streetNumber: null,
            postalCode: null,
            locality: null,
            municipality: null,
            province: null,
            region: null,
            country: null,
            coordinates: null,
            isValid: false,
            errors: []
        };
    }

    private setAddress() {
        this.autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement,
            {componentRestrictions: {country: 'it'}, types: ['geocode']});
        this.autocompleteListener = this.autocomplete.addListener('place_changed', () => {
            this.ngZone.run(() => {
                const place = this.autocomplete.getPlace();
                this.initAddress();
                if (!this.isPlaceValid(place)) {
                    return;
                }
                this.changeDetectorRef.markForCheck();
                if (!!place.geometry) {
                    this.address.coordinates = {
                        latitude: place.geometry.location.lat(),
                        longitude: place.geometry.location.lng()
                    };
                    this.parsePlaceResult(place);
                    this.formControlRef?.setValue(place.formatted_address);
                } else {
                    this.address.country = this.searchElementRef.nativeElement.value;
                    this.formControlRef.setValue(this.searchElementRef.nativeElement.value);
                }
                this.address.isValid = this.address.errors.length === 0;
                this.setFormErrors();
                this.addressChange.next(this.address);
            });
        });
    }

    private isPlaceValid(place: google.maps.places.PlaceResult): boolean {
        if (isDefined(place.geometry)) {
            return true;
        }
        if (place.name === '' && this.isRequired) {
            this.address.errors = ['Missing address'];
            this.address.isValid = false;
            this.setFormErrors();
            this.formControlRef.setValue(place.formatted_address);
            this.addressChange.next(this.address);
            return false;
        }
        return true;
    }

    private parsePlaceResult(place: google.maps.places.PlaceResult) {
        place.address_components.forEach(addressComponent => {
            if (addressComponent.types.includes(GoogleTypes.STREET_NUMBER)) {
                const originalAddress = this.searchElementRef.nativeElement.value.split(',');
                const originalStreetNumber = originalAddress.length > 1 ? originalAddress[1].trim() : null;
                this.address.streetNumber = addressComponent.long_name;
                if (originalStreetNumber && originalStreetNumber !== addressComponent.long_name) {
                    this.address.streetNumber = originalStreetNumber;
                }
            } else if (addressComponent.types.includes(GoogleTypes.ROUTE)) {
                this.address.street = addressComponent.long_name;
            } else if (addressComponent.types.includes(GoogleTypes.LOCALITY)) {
                this.address.locality = addressComponent.long_name;
            } else if (addressComponent.types.includes(GoogleTypes.ADMINISTRATIVE_AREA_LEVEL_3)) {
                this.address.municipality = addressComponent.long_name;
            } else if (addressComponent.types.includes(GoogleTypes.ADMINISTRATIVE_AREA_LEVEL_2)) {
                this.address.province = addressComponent.short_name;
            } else if (addressComponent.types.includes(GoogleTypes.ADMINISTRATIVE_AREA_LEVEL_1)) {
                this.address.region = addressComponent.short_name;
            } else if (addressComponent.types.includes(GoogleTypes.POSTAL_CODE)) {
                this.address.postalCode = addressComponent.long_name;
            } else if (addressComponent.types.includes(GoogleTypes.COUNTRY)) {
                this.address.country = addressComponent.long_name;
            }
        });
    }

    private setFormErrors() {
        this.formControlRef.setErrors(!this.address.isValid ? {invalid: true} : null);
    }
}

export function mkAddress(address: Address): ECMemberAddress | null {
    if (!isDefined(address)) {
        return null;
    }
    const result = {
        street: address.street,
        street_number: address.streetNumber,
        city: address.municipality,
        postal_code: address.postalCode,
        province: address.province
    };
    // country is always defined, from region downwards it might not
    if (!isDefined(address.region)) {
        result.province = address.country;
    } else if (!isDefined(address.province)) {
        result.province = address.country;
        result.city = address.region;
    }
    return result;
}
